<template>
  <div>
    <PageHeader title="Printmodule" />

    <div class="tw-my-8">
      <div v-if="noLinkedOffice" class="tw-text-error">
        Koppel een primair kantoor aan dit pand om de printmodule te gebruiken.
      </div>

      <div v-else class="tw-grid md:tw-grid-cols-2 tw-gap-4">
        <FormulateForm
          v-model="values"
          name="printForm"
          invalid-message="Gelieve de verplichte velden correct in te vullen."
        >
          <FormulateInput
            type="select"
            name="template"
            label="Template"
            placeholder="Selecteer een template"
            :options="templates"
            validation="required"
            @input="templateSelected"
          />

          <div v-if="existingPrint">
            Er werd al een print gemaakt met deze template op {{ existingPrint.updated_on | datetime }}.
            <button
              type="button"
              class="link"
              @click="pdfUrl = existingPrint.url"
            >
              Klik hier
            </button>
            om die te bekijken
          </div>

          <FormulateInput
            v-if="requiresModule"
            type="select"
            name="selectedModule"
            label="Module"
            placeholder="Selecteer een module"
            :options="modules"
            :value="defaultModule.value"
            validation="required"
          />
          <FormulateInput
            v-for="count in languageCount"
            :key="count"
            v-model="languages[count]"
            type="select"
            :name="`lang${count}`"
            :label="`Taal ${count}`"
            :placeholder="`Selecteer taal ${count}`"
            :options="USER_LANG_OPTIONS"
            :value="['nl', 'fr', 'en'][count - 1]"
            validation="required"
          />
          <span v-if="notEnoughPictures" class="tw-text-error">
            Het pand moet minstens {{ notEnoughPictures }} publieke {{ notEnoughPictures === 1 ? 'foto' : 'foto\'s'}} hebben voor deze print.
          </span>
          <template v-else>
            <Tabs v-if="languageTabs.length" :tabs="languageTabs" class="tw-my-4">
              <div v-for="(count, index) in languageCount" :key="count" :slot="`lang${count}`">
                <FormulateInput
                  v-model="title[index]"
                  type="text"
                  :name="`title${count}`"
                  label="Titel"
                  :outer-class="['tw-mt-0']"
                />
                <FormulateInput
                  v-model="description[index]"
                  type="textarea"
                  :name="`description${count}`"
                  label="Omschrijving"
                  :outer-class="['tw-mb-0']"
                />
              </div>
            </Tabs>

            <div v-if="values.template" class="tw-mb-8">
              <PictureSelector
                v-model="selectedPictures"
                :pictures="printData.pictures"
                :min="pictureCount"
                :max="pictureCount"
              />
            </div>

            <FormulateErrors />
            <div class="tw-flex tw-flex-row tw-gap-2">
              <FormulateInput
                type="submit"
                title="Voorbeeld"
                :disabled="generatingPreview || generatingPrint"
                :input-class="['tw-w-full']"
                outer-class="tw-m-0 tw-flex-grow"
                @click="submit(true)"
              >
                <i
                  :class="[
                    'fas tw-mr-1',
                    generatingPreview ? 'fa-spinner-third fa-spin' : 'fa-eye'
                  ]"
                />
                Voorbeeld
              </FormulateInput>
              <FormulateInput
                v-if="printPreviewed"
                type="submit"
                title="Maak print"
                :disabled="generatingPreview || generatingPrint"
                :input-class="['tw-bg-primary tw-w-full']"
                outer-class="tw-m-0 tw-flex-grow"
                @click="submit(false)"
              >
                <i
                  :class="[
                    'fas tw-mr-1',
                    generatingPrint ? 'fa-spinner-third fa-spin' : 'fa-print'
                  ]"
                />
                Maak print (betalend)
              </FormulateInput>
            </div>
          </template>
        </FormulateForm>
        <iframe
          v-if="pdfUrl"
          id="pdf_frame"
          type="application/pdf"
          :src="pdfUrl"
          class="tw-my-4 tw-w-full tw-h-full tw-min-h-[468px]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

import Tabs from '@/components/Tabs.vue'
import PageHeader from '@/components/PageHeader.vue'
import PictureSelector from '@/components/PictureSelector'

import { createV2Document, getV2Templates, pollV2Gutenborg, getModules } from '@/services/gutenborgService'
import { getPropertyOffices, getPropertyPrintData, storeGutenborgFile, getPropertyFiles } from '@/services/properties'
import { errorModal } from '@/modalMessages'
import { poll, USER_LANG_OPTIONS } from '@/utils/helpers'
import { createLog } from '@/services/apiService'

export default {
  name: 'NewEntityPrint',
  components: {
    PictureSelector,
    PageHeader,
    Tabs
  },
  constants: {
    USER_LANG_OPTIONS
  },
  data () {
    return {
      noLinkedOffice: false,
      templates: [],
      modules: [],
      languageCount: 0,
      languages: {},
      values: {},
      printData: {},
      title: [],
      description: [],
      printPreviewed: false,
      generatingPreview: false,
      generatingPrint: false,
      pdfUrl: null,
      requiresModule: false,
      existingPrint: null,
      selectedTemplateId: null,
      selectedPictures: [],
      pictureCount: 3
    }
  },
  computed: {
    propertyId () {
      return this.$route.params.id
    },
    languageTabs () {
      const tabs = []
      // Language count starts with 1
      for (let count = 1; count <= this.languageCount; count++) {
        const name = this.languages[count] // language
        if (name) tabs.push({ name: name.toUpperCase(), target: `lang${count}` })
      }
      return tabs
    },
    languagesArray () {
      // We have to slice to remove the extra languages when the template doesn't require them.
      let array = Object.values(this.languages)
      if (array.length > this.languageCount) array = array.slice(0, this.languageCount)
      return array
    },
    notEnoughPictures () {
      // Checks if the pictures are not enough, then returns the number of required pictures
      return (this.printData?.pictures?.length < this.pictureCount) && this.pictureCount
    },
    defaultModule () {
      return this.modules.find(item => item.is_default)
    }
  },
  watch: {
    languagesArray () {
      this.fetchPrintInformation()
    }
  },
  created () {
    this.init()
  },
  methods: {
    templateSelected (template) {
      const { language_count, output_type, requires_module } = template
      this.languageCount = language_count || 0
      this.requiresModule = requires_module || false
      this.selectedTemplateId = template.id
      this.pictureCount = template.picture_count
      this.$set(this.values, 'output_type', output_type)
      this.fetchPrintInformation()
    },
    fetchPrintInformation: debounce(
      /*
        WE need to fetch the complete print information on both language and template change
        Sometimes when changing templates, multiple languages are rendered, which ends up making multiple API calls.
        The last API calls are the accurate ones, and it only happens when the new language inputs are first rendered.
        On subsequent language/template changes, it won't happen.

        Debounce is supposed to help.
      */
      function () {
        this.fetchExistingPrint(this.selectedTemplateId)
        this.fetchPropertyPrintData(this.languagesArray, this.values.output_type)
      },
      5 // 5ms is enough because we are racing javascript code execution, not human input. Can be changed if we get proven wrong on slower devices.
    ),
    async init () {
      try {
        const offices = await getPropertyOffices(this.propertyId)
        if (!offices?.data?.count) {
          this.noLinkedOffice = true
          return offices
        }

        const [templates, modules] = await Promise.all([
          getV2Templates({ type: 'print' }),
          getModules({ type: 0 }) // PRINT_TYPE = 0
        ])

        this.modules = modules.data.map(({ id, name, is_default, ...module }) => {
          return {
            id,
            value: { id, name, ...module },
            label: name,
            is_default
          }
        })
        this.templates = templates.data.map(({ id, name, ...template }) => {
          return {
            id,
            value: { id, name, ...template },
            label: name
          }
        })

        return [templates, modules]
      } catch (error) {
        errorModal('Printmodule kon niet geïnitialiseerd worden.')
      }
    },
    async fetchPropertyPrintData (languages, output_type) {
      try {
        if (!(languages.length && output_type)) return

        const payload = {
          propertyId: this.propertyId,
          // etalagefiche = Storefront print. This is used to determine which utm tags to use for the QR url
          params: { languages, output_type, source: 'etalagefiche' }
        }
        const response = await getPropertyPrintData(payload)
        const printData = response.data

        this.printData = printData
        this.title = printData.title
        this.description = printData.description
        this.selectedPictures = printData.pictures?.slice(0, this.pictureCount)

        return response
      } catch (error) {
        errorModal('Printgegevens konden niet worden geladen')
      }
    },
    async submit (preview) {
      try {
        const { template, selectedModule, ...values } = this.values
        preview ? this.generatingPreview = true : this.generatingPrint = true

        let module = null
        if (selectedModule) module = selectedModule[this.languagesArray[0]]

        const payload = {
          type: 'print',
          template: template.id,
          preview,
          data: {
            ...this.printData,
            title: this.title,
            description: this.description,
            pictures: this.selectedPictures,
            module
          },
          ...values
        }

        const generate = await createV2Document(payload)
        const pollResult = await poll(generate?.data?.job_id, pollV2Gutenborg, 1000)
        this.pdfUrl = pollResult.url

        if (preview) this.printPreviewed = true
        else {
          const payload = {
            url: pollResult.url,
            template_id: template.id,
            language_code: this.languagesArray.join('_'),
            template_name: template.name,
            type: template.name.includes('Pandenboekje') ? 'brochure' : 'print'
          }
          await storeGutenborgFile(this.propertyId, payload)

          let actionId = 10 // Booklet action id
          // Hack to determine whether the template is a booklet or an window print ("Etalagefiche")
          // A possible improvement would be to set more specific types in the back-end
          // Currently booklets and window prints are grouped under the "print" type
          if (template.name.includes('Etalagefiche')) {
            actionId = 9 // Window print action id
          }
          const logPayload = {
            action_id: actionId,
            content_object_id: this.propertyId,
            content_object_class: 'property',
            meta: { name: template.name }
          }
          await createLog(logPayload)
        }
        return pollResult
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het genereren van jouw print, gelieve nogmaals te proberen')
      } finally {
        preview ? this.generatingPreview = false : this.generatingPrint = false
      }
    },
    async fetchExistingPrint (template_id) {
      if (!this.languagesArray.length && this.selectedTemplateId) return

      this.existingPrint = null
      const payload = {
        propertyId: this.propertyId,
        params: {
          template_id,
          folder_name: 'Prints',
          language_code: this.languagesArray.join('_')
        }
      }
      const response = await getPropertyFiles(payload)
      // There will always be only 1 print per template as it is overriden and updated each time we generate a new print.
      this.existingPrint = response.data?.results?.[0]
      return response
    }
  }
}
</script>
