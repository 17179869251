<template>
  <div>
    <button
      type="button"
      class="formulate-button tw-w-full tw-mb-4"
      @click="showPictureModal"
    >
      <i class="far fa-image tw-mr-2" />
      Kies foto
    </button>

    <div v-if="selections.length" class="tw-flex tw-flex-row tw-gap-4 tw-overflow-x-auto">
      <img
        v-for="picture in selections"
        :key="picture.id"
        :src="picture.url"
        alt="Foto"
        class="tw-block tw-rounded-md"
      />
    </div>

    <BaseModal ref="modal" title="Kies een foto" max-width="tw-max-w-7xl" @hide="satisfyMinSelections">
      <div class="tw-grid tw-grid-flow-row tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-4">
        <label
          v-for="picture in pictures"
          :key="`${idPrefix}_${picture.id}`"
          :for="`${idPrefix}_${picture.id}`"
          class="tw-cursor-pointer tw-h-full tw-w-full"
        >
          <img
            :src="picture.url"
            alt="Pand foto"
            :class="[
              'tw-h-full tw-w-full tw-object-cover',
              { 'tw-ring-4 tw-ring-primary': selections.find(selection => selection.id === picture.id) }
            ]"
          />
          <input
            v-model="selections"
            :id="`${idPrefix}_${picture.id}`"
            :value="picture"
            type="checkbox"
            :name="`pictures_${idPrefix}`"
            class="tw-sr-only"
            @input="pictureSelected"
          />
        </label>
      </div>
    </BaseModal>
  </div>
</template>

<script>
export default {
  name: 'PictureSelector',
  model: {
    prop: 'values',
    event: 'picture-selected'
  },
  props: {
    pictures: {
      type: Array,
      default: () => []
    },
    values: {
      type: Array,
      default: () => []
    },
    min: {
      // Maximum number of allowed selections
      type: Number,
      default: 1
    },
    max: {
      // Maximum number of allowed selections
      type: Number
    },
    idPrefix: {
      // Required to avoid conflicts because of same pictures and same properties
      type: String,
      default: `picture_${Math.random()}`
    }
  },
  computed: {
    selections: {
      get () {
        return this.values
      },
      set (value) {
        return this.$emit('picture-selected', value)
      }
    }
  },
  methods: {
    showPictureModal () {
      this.$refs.modal.show()
    },
    satisfyMinSelections () {
      // If the user selects less than the required min. number of pictures, we automatically select the pictures for them.
      if (this.selections.length < this.min) {
        const selectionIds = this.selections.map(selection => selection.id)
        const remainingPictures = this.pictures.filter(picture => !selectionIds.includes(picture.id))
        const remainingCount = this.min - this.selections.length
        this.selections = [...this.selections, ...remainingPictures.slice(0, remainingCount)]
        return remainingPictures
      }
    },
    pictureSelected () {
      return this.selections.length >= this.max ? this.selections.shift() : null
    }
  }
}
</script>
